import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useLocales } from 'src/locales';
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryBranchList } from 'src/api/branch';
import { useAuthContext } from 'src/auth/hooks';

interface Props {
  value: string;
  width?: number | null;
  clearValue?: boolean;
  requiredFieldMsg?: string | null;
  onChangeUser: (x: string) => void;
}

export default function BranchSelection({
  value,
  requiredFieldMsg,
  clearValue,
  onChangeUser,
  width,
}: Props) {
  const { t } = useLocales();
  const { permissions } = useAuthContext();
  const { data, refetch } = useQueryBranchList(undefined);

  const handleChangeValue = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChangeUser(value === event.target.value?.toString() ? '' : event.target.value.toString());
    },
    [onChangeUser]
  );
  useEffect(() => {
    if (permissions?.includes('list_branch')) {
      refetch();
    }
  }, [permissions]);

  const BranchList = useMemo(() => {
    return data?.data?.data || [];
  }, [data]);

  if (permissions?.includes('list_branch') && BranchList?.length > 0) {
    return (
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: width || 1 },
        }}
      >
        <InputLabel>{t('select_branch')}</InputLabel>

        <Select
          value={value}
          onChange={handleChangeValue}
          error={requiredFieldMsg && requiredFieldMsg?.length > 0 ? true : false}
          input={<OutlinedInput label={t('select_branch')} />}
          renderValue={(selected) => {
            return BranchList?.find((x) => x?.id?.toString() === selected?.toString())?.name;
          }}
          sx={{ textTransform: 'capitalize' }}
        >
          {clearValue && <MenuItem value={''}>{t('display_all')}</MenuItem>}
          {BranchList?.map((type, i) => (
            <MenuItem key={i} value={type?.id || ''}>
              {type?.name}
            </MenuItem>
          ))}
        </Select>
        {requiredFieldMsg && (
          <Typography fontSize={'small'} pl={2} mt={0.5} color={'#FF5630'}>
            {requiredFieldMsg}
          </Typography>
        )}
      </FormControl>
    );
  }
  return <></>;
}
