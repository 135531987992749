import {
  Box,
  Button,
  Switch,
  TextField,
  CardHeader,
  CardContent,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  Stack,
} from '@mui/material';
import { useMemo } from 'react';
import Card from '@mui/material/Card';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import Label from 'src/components/label';
import { useRouter } from 'src/routes/hook';
import Iconify from 'src/components/iconify';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { useQueryUserDetail } from 'src/api/user';
import { useSnackbar } from 'src/components/snackbar';
import TableContainer from '@mui/material/TableContainer';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import PermissionGuard from 'src/components/permission/role-guard';

export const POST_SORT_OPTIONS = [
  { value: 'ASC', label: 'ascending' },
  { value: 'DESC', label: 'descending' },
];

export interface SearchOptions {
  title: string;
  value: string;
}
export interface SearchOptionsProps {
  limit: string;
  page: string;
  sort: string;
}

const defaultFilters: SearchOptionsProps = {
  limit: '30',
  page: '1',
  sort: 'DESC',
};

// ----------------------------------------------------------------------
interface Props {
  id: string;
}
export default function ({ id }: Props) {
  const { t } = useLocales();
  const router = useRouter();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQueryUserDetail(
    String(id),
    { with_branch: 1, with_credit: 1, with_created_by: 1 },
    {
      loadFirst: true,
      onSuccess: ({ data, message }) => {
        enqueueSnackbar(message);
      },
      onError: (er) => enqueueSnackbar(er?.message, { variant: 'error' }),
    }
  );

  const resultData = useMemo(() => {
    return data?.data;
  }, [data]);

  const status = resultData?.is_active === 1 ? true : false;

  return (
    <PermissionGuard hasContent permission={'detail_staff'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'md'}>
        <CustomBreadcrumbs
          heading={t('user_detail')}
          links={[{}]}
          // action={
          //   <Button
          //     onClick={() => router?.push(paths.dashboard.user.edit(id))}
          //     variant={'contained'}
          //     color="primary"
          //   >
          //     {t('edit')}
          //   </Button>
          // }
          sx={{
            mb: { xs: 3, md: 2 },
          }}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Grid container spacing={3} xs={12} md={12}>
            <Grid xs={12} md={12}>
              <Card>
                <CardHeader
                  title=""
                  action={
                    <Stack display="flex" flexDirection={'row'} gap={2} px={2}>
                      <Label variant="soft" color={status ? 'success' : 'error'}>
                        {t(`${status ? 'active' : 'in_active'}`)}
                      </Label>
                      <Tooltip title={t('edit')}>
                        <Stack
                          onClick={() => router.push(paths.dashboard.user.edit(id || ''))}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Iconify icon="lucide:edit" width={25} />
                        </Stack>
                      </Tooltip>
                    </Stack>
                  }
                />

                <CardContent>
                  <Box
                    mb={2}
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                    }}
                  >
                    <TextField
                      fullWidth
                      maxRows={4}
                      variant={'outlined'}
                      label={t('username')}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="solar:user-rounded-bold" width={24} />
                          </InputAdornment>
                        ),
                      }}
                      value={resultData?.username || ''}
                    />
                    <TextField
                      fullWidth
                      maxRows={4}
                      variant={'outlined'}
                      label={t('name')}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="solar:user-rounded-bold" width={24} />
                          </InputAdornment>
                        ),
                      }}
                      value={resultData?.name || ''}
                    />

                    <TextField
                      fullWidth
                      variant={'outlined'}
                      label={t('balance')}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="entypo:wallet" width={24} />
                          </InputAdornment>
                        ),
                      }}
                      value={parseFloat(resultData?.credit?.balance?.toString() || '0')}
                    />
                    <TextField
                      fullWidth
                      multiline
                      maxRows={4}
                      variant={'outlined'}
                      label={t('phone_number')}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="solar:phone-bold" width={24} />
                          </InputAdornment>
                        ),
                      }}
                      value={resultData?.contact || ''}
                    />
                    <TextField
                      fullWidth
                      multiline
                      maxRows={4}
                      variant={'outlined'}
                      label={t('branch')}
                      value={resultData?.branch?.name || ''}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      multiline
                      maxRows={4}
                      variant={'outlined'}
                      label={t('created_by')}
                      value={resultData?.created_by?.name || ''}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  {/* <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        color="success"
                        checked={resultData?.is_active === 1 ? true : false}
                      />
                    }
                    label={t('active_status')}
                    sx={{ mt: 1 }}
                  /> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TableContainer>
      </Container>
    </PermissionGuard>
  );
}
