import { useLocales } from 'src/locales';
import Label from 'src/components/label';
import { Types } from 'src/generated/types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { IconButton, MenuItem, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { khCurrency } from 'src/utils/format-img-url';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

type Props = {
  onDepositUser: () => void;
  onWithdrawUser: () => void;
  onEditRow: () => void;
  onDetailPage: () => void;
  onQuikPayment: () => void;
  row: Types.User.UserInfo;
};

export default function UserTableRow({
  row,
  onDetailPage,
  onDepositUser,
  onWithdrawUser,
  onEditRow,
}: Props) {
  const { id, name, username, branch, is_active, created_by, credit } = row;
  const { t } = useLocales();
  const history = useRouter();
  const popover = usePopover();
  const { hasPermission } = useAuthContext();

  return (
    <>
      <TableRow hover onClick={(e) => onDetailPage()} sx={{ cursor: 'pointer' }}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{id}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={<Typography fontSize={'small'}>{username}</Typography>}
            sx={{ cursor: 'pointer' }}
            primaryTypographyProps={{ typography: 'body2', fontSize: 'small', fontWeight: 'bold' }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={<Typography fontSize={'small'}>{name}</Typography>}
            sx={{ cursor: 'pointer' }}
            primaryTypographyProps={{ typography: 'body2', fontSize: 'small', fontWeight: 'bold' }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography fontSize="small" fontWeight={'bold'}>
            <Label variant="soft" color={'default'}>
              {branch?.name}
            </Label>
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
          <Typography fontSize="small">
            {khCurrency(parseFloat(credit?.balance?.toString() || ''))}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} align="center">
          <Typography fontSize="small">
            <Label variant="soft" color={is_active === 1 ? 'success' : 'error'}>
              {is_active === 1 ? t('active') : t('in_active')}
            </Label>
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography fontSize="small">{created_by?.name}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} align="center">
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              popover.onOpen(e);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 170 }}
      >
        <MenuItem onClick={(e) => onDetailPage()}>
          <Iconify icon="solar:pen-bold" />
          {t('view_details')}
        </MenuItem>
        {hasPermission('deposit_staff_operation') && (
          <MenuItem onClick={(e) => onDepositUser()}>
            <Iconify icon="ph:hand-deposit-duotone" />
            {t('deposit')}
          </MenuItem>
        )}
        {hasPermission('withdraw_staff_operation') && (
          <MenuItem onClick={(e) => onWithdrawUser()}>
            <Iconify icon="ph:hand-withdraw-duotone" />
            {t('withdraw')}
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            history?.push(paths.dashboard.log.user(id?.toString() || ''));
          }}
        >
          <Iconify icon="ic:sharp-manage-history" />
          {t('view_logs')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
