import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQueryNotification } from '../../api/pawn';
import { Types } from 'src/generated/types';
import { useAuthContext } from 'src/auth/hooks';
import { useQueryRepaymentCount } from 'src/api/reports';

interface NotificationContextProps {
  isloadingCount?: boolean;
  notificationList?: Types.Report.RepaymentCountItem | null;
  refetchNotification: () => void;
}

const NotificationContext = React.createContext<NotificationContextProps>({
  isloadingCount: false,
  notificationList: null,
  refetchNotification: () => '',
});

export function NotificationProvider(props: React.PropsWithChildren<unknown>) {
  const { user, permissions, child } = useAuthContext();

  //notification remider list
  const {
    data: RepaymentCount,
    isLoading: isloadingCount,
    refetch: onRefectReminder,
  } = useQueryRepaymentCount({
    loadFirst: false,
  });

  const notificationList = useMemo(() => {
    return RepaymentCount?.data || null;
  }, [RepaymentCount]);

  useEffect(() => {
    if (user) {
      onRefectReminder();
    }
  }, [user]);

  const refetchNotification = useCallback(() => {
    onRefectReminder();
  }, [RepaymentCount]);

  return (
    <NotificationContext.Provider value={{ isloadingCount, notificationList, refetchNotification }}>
      {props?.children}
    </NotificationContext.Provider>
  );
}
export function useNotification() {
  return useContext(NotificationContext);
}
