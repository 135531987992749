import { useSearchParams } from 'react-router-dom';

export function useQueryString() {
  const [searchParams, setSearchParams] = useSearchParams();
  const getQueryString = (key: string, defaultValue: any = null) => {
    const query = searchParams.get(key);
    return !!query && query.trim() !== 'null' ? query.trim() : defaultValue;
  };
  const setQueryString = (params: any) => {
    const prevParams: any = {};
    searchParams.forEach((value, key) => {
      prevParams[key] = value;
    });
    const query = Object.entries({ ...prevParams, ...params }).reduce(
      (obj, p) => Object.assign(obj, { [p[0]]: String(p[1]) }),
      {}
    );
    if (JSON.stringify(prevParams) !== JSON.stringify(query)) {
      setSearchParams(query);
    }
  };
  return { getQueryString, setQueryString };
}
