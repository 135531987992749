// ----------------------------------------------------------------------
import {
  Card,
  Grid,
  Stack,
  Container,
  CardHeader,
  IconButton,
  CardContent,
  InputAdornment,
} from '@mui/material';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { useLocales } from 'src/locales';
import endpoints from 'src/api/endpoints';
import { useForm } from 'react-hook-form';
import PostSearch from './search-username';
import { Types } from 'src/generated/types';
import { enqueueSnackbar } from 'notistack';

import Iconify from 'src/components/iconify';
import { Upload } from 'src/components/upload';
import { useQueryUserList } from 'src/api/user';
import { useBoolean } from 'src/hooks/use-boolean';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatUrl } from 'src/utils/format-img-url';
import axiosInstance from 'src/utils/axios-instanct';
import { useDebounce } from 'src/hooks/use-debounce';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutateTransfer } from 'src/api/operation';
import { useSettingsContext } from 'src/components/settings';
import PermissionGuard from 'src/components/permission/role-guard';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface ClearableAutocompleteRef {
  clear: () => void;
}

export default function () {
  const { t } = useLocales();
  const password = useBoolean();
  const settings = useSettingsContext();
  const autoCompleteRef = useRef<ClearableAutocompleteRef>();
  const [isLoading, setIsLoading] = useState(false);
  const domain = process.env?.REACT_APP_HOST_API;
  const token = document.cookie.split('accessToken=')[1].split('; XSRF-')[0];
  const [file, setFile] = useState<File | string | null>(null);

  const NewUserSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    to_user_id: Yup.string().required('Username is required'),
    receipt_url: Yup.string(),
    password: Yup.string().required('Password is required'),
  });

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 1000);

  const [searchResults, setSearchResult] = useState<Types.User.UserInfo[]>([]);
  const { isLoading: isLoadingUserTransfer, mutate: onUserTransfer } = useMutateTransfer(
    undefined,
    {
      onSuccess: ({ message }) => {
        reset();
        handleClearAutoComplete();
        enqueueSnackbar(message);
      },
      onError: (er) => {
        enqueueSnackbar(er.message, { variant: 'error' });
      },
    }
  );
  const { isLoading: isLoadingUserList, refetch: getUserList } = useQueryUserList(undefined, {
    onSuccess: ({ data, message }) => {
      setSearchResult(data?.data);
    },
    onError: (er) => {},
  });

  const defaultValues = useMemo(
    () => ({
      amount: '',
      to_user_id: '',
      receipt_url: '',
      password: '',
    }),
    []
  );
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const handleSearch = useCallback((inputValue: string) => {
    setSearchQuery(inputValue);
  }, []);

  const onGetUserId = (id: string) => {
    setValue('to_user_id', id);
  };
  const handleClearAutoComplete = () => {
    if (autoCompleteRef?.current?.clear()) {
      autoCompleteRef?.current.clear();
    }
  };

  const onSubmit = handleSubmit(async (_params) => {
    const variable = {
      to_user_id: Number(_params?.to_user_id),
      amount: Number(_params?.amount),
      receipt_url: _params?.receipt_url,
      password: _params?.password,
    };
    await onUserTransfer(variable);
  });

  const getSearchResults = useMemo(() => {
    return searchResults;
  }, [searchResults]);

  const onUploadCustomerImage = async () => {
    const formData = new FormData();
    formData.append('image', file as any);
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${domain}${endpoints.customer.upload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setValue('receipt_url', response?.data?.data?.image_url || '');
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDropSingleFile = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
    },
    [setValue]
  );
  const imageUrl = watch('receipt_url');

  useEffect(() => {
    if (file) {
      onUploadCustomerImage();
    }
  }, [file]);

  useEffect(() => {
    if (debouncedQuery.length > 3) {
      getUserList({ search_name: debouncedQuery });
    }
  }, [debouncedQuery]);

  return (
    <PermissionGuard hasContent permission={'transfer_operation'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'md'} sx={{ mt: 5 }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid gridColumn={12}>
            <Card sx={{ p: 3 }}>
              <CardHeader title={t('transfer_to_staff')} />
              <CardContent>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                  }}
                >
                  <PostSearch
                    ref={autoCompleteRef}
                    loading={isLoadingUserList}
                    query={debouncedQuery}
                    onSearch={handleSearch}
                    results={getSearchResults || []}
                    onGetUserId={onGetUserId}
                    requiredFieldMsg={errors?.to_user_id?.message}
                    placeholder="search_user_name"
                  />

                  <RHFTextField type="text" size="medium" name="amount" label={t(`${'amount'}`)} />

                  <RHFTextField
                    name="password"
                    label={t('password')}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Upload
                    thumbnail
                    loading={isLoading}
                    file={formatUrl(imageUrl || '')}
                    accept={{ 'image/*': [] }}
                    maxSize={3145728}
                    onDrop={handleDropSingleFile}
                    onDelete={() => {
                      setFile(null);
                      setValue('receipt_url', '');
                    }}
                  />
                </Box>
                <Stack
                  spacing={1.5}
                  mt={4}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting || isLoadingUserTransfer}
                  >
                    {t('submit')}
                  </LoadingButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </FormProvider>
      </Container>
    </PermissionGuard>
  );
}
