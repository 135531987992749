import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { Types } from 'src/generated/types';
import { useRouter } from 'src/routes/hook';
import Iconify from 'src/components/iconify';
import { Button, Stack } from '@mui/material';
import { useState, useCallback } from 'react';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import { useQueryUserList } from 'src/api/user';
import Scrollbar from 'src/components/scrollbar';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';
import { IUserTableFilterValue } from 'src/types/user';
import PostSort from '../components/user-list/post-sort';
import { DepositView, WithdrawView } from 'src/sections/operation/view';
import TableContainer from '@mui/material/TableContainer';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import UserTableRow from '../components/user-list/user-table-row';
import PermissionGuard from 'src/components/permission/role-guard';
import UserTableToolbar from '../components/user-list/user-table-toolbar';

export const POST_SORT_OPTIONS = [
  { value: 'ASC', label: 'ascending' },
  { value: 'DESC', label: 'descending' },
];

const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'username', label: 'username' },
  { id: 'name', label: 'name' },
  { id: 'branch', label: 'branch' },
  { id: 'balance', label: 'balance', align: 'right' },
  { id: 'status', label: 'status', align: 'center' },
  { id: 'created_by', label: 'created_by' },
  { id: '', label: '', align: 'center', width: '40px' },
];

export const _search_by: SearchOptions[] = [
  { value: 'search_name', title: 'search_name' },
  { value: 'search_username', title: 'search_username' },
];

export interface SearchOptions {
  title: string;
  value: string;
}
export interface SearchOptionsProps {
  name?: string;
  limit: string;
  page: string;
  sort: string;
  role: string;
  branch_id?: string | null;
  is_active?: string | null;
  searchOptions: string;
}

const defaultFilters: SearchOptionsProps = {
  name: '',
  limit: '30',
  page: '1',
  sort: 'DESC',
  role: 'staff',
  branch_id: null,
  is_active: null,
  searchOptions: 'search_name',
};

// ----------------------------------------------------------------------

export default function () {
  const table = useTable();
  const router = useRouter();
  const { t } = useLocales();
  const isDeposit = useBoolean();
  const isWithdraw = useBoolean();
  const quickPayment = useBoolean();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState(defaultFilters);
  const [userId, setUserId] = useState<number | null>(null);
  const [tableData, setTableData] = useState<Types.User.UserInfo[]>([]);

  const searchOptions = JSON.parse(
    `${'{"' + filters?.searchOptions + '":"' + filters.name + '"}'}`
  );

  const {
    data,
    isLoading,
    refetch: onRefectUserList,
  } = useQueryUserList(
    {
      sort: filters.sort,
      page: filters?.page,
      limit: filters?.limit,
      role: filters?.role || null,
      is_active: filters.is_active || null,
      branch_id: filters?.branch_id || null,
      ...searchOptions,
      with_branch: 1,
      with_credit: 1,
      with_created_by: 1,
    },
    {
      loadFirst: true,
      onSuccess: ({ data, message }) => {
        setTableData(data?.data || []);
        setFilters((prevState) => ({
          ...prevState,
          page: data?.current_page.toString(),
          limit: data?.per_page.toString(),
        }));
        enqueueSnackbar(message);
      },
      onError: (er) => enqueueSnackbar(er?.message, { variant: 'error' }),
    }
  );

  const denseHeight = table.dense ? 52 : 72;

  const notFound = tableData?.length === 0;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.customer.edit(id));
    },
    [router]
  );

  const handleDetailPage = useCallback(
    (id: string) => {
      router.push(paths.dashboard.user.detail(id));
    },
    [router]
  );

  const onHandleSearch = () => {
    onRefectUserList();
  };

  const handleSortBy = useCallback((newValue: string) => {
    handleFilters('sort', newValue);
    onRefectUserList({ sort: newValue });
  }, []);

  const onPageChange = (_page: number) => {
    const p = _page + 1;

    if (p !== Number(filters.page)) {
      setFilters((prevState) => ({
        ...prevState,
        page: p.toString(),
      }));
      onRefectUserList();
    }
  };

  const onRowsPerPageChange = (_limit: number) => {
    if (_limit !== Number(filters?.limit)) {
      setFilters((prevState) => ({
        ...prevState,
        limit: _limit.toString(),
      }));
      onRefectUserList({ limit: _limit.toString() });
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <PermissionGuard hasContent permission={'list_staff'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={t('staff_lists')}
          links={[{}]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.user.create}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('create_staff')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 2 },
          }}
        />
        <Stack
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Stack></Stack>
          <PostSort sort={filters.sort} onSort={handleSortBy} sortOptions={POST_SORT_OPTIONS} />
        </Stack>
        <Card>
          <Scrollbar>
            <UserTableToolbar
              isLoading={isLoading}
              filters={filters}
              onFilters={handleFilters}
              onHandleSearch={onHandleSearch}
              searchOptions={_search_by}
            />
          </Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row?.id?.toString() || '')
                    )
                  }
                />
                <TableBody>
                  {tableData?.map((row, i) => (
                    <UserTableRow
                      key={i}
                      row={row}
                      onQuikPayment={() => {
                        quickPayment.onTrue();
                      }}
                      onDepositUser={() => {
                        setUserId(row?.id);
                        isDeposit.onTrue();
                      }}
                      onWithdrawUser={() => {
                        isWithdraw.onTrue();
                        setUserId(row?.id);
                      }}
                      onEditRow={() => handleEditRow(row?.id?.toString() || '')}
                      onDetailPage={() => handleDetailPage(row?.id?.toString() || '')}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          {!isLoading && (
            <TablePaginationCustom
              count={tableData?.length ?? 0}
              page={table.page}
              rowsPerPage={Number(data?.data?.per_page || 0)}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
              rowsPerPageOptions={[30, 50, 100, 200]}
              onPageChange={(_, p) => onPageChange(p)}
              onRowsPerPageChange={(e) => onRowsPerPageChange(Number(e.target.value))}
            />
          )}
        </Card>
        <DepositView
          open={isDeposit.value}
          onClose={isDeposit.onFalse}
          depositId={userId}
          depositType="user"
          title={'Deposit'}
          onSuccess={onRefectUserList}
        />
        <WithdrawView
          open={isWithdraw.value}
          onClose={isWithdraw.onFalse}
          withdrawId={userId}
          withdrawType="user"
          title={'withdraw'}
          onSuccess={onRefectUserList}
        />
      </Container>
    </PermissionGuard>
  );
}
