import endpoints from './endpoints';
import { Types } from 'src/generated/types';
import { SWROptions, useMutateSWR, useQuerySWR } from 'src/utils/swr-instanct';

export interface MortgageListParams {
  sort?: string | null;
  page?: string | null;
  limit?: string | null;
  with_credit?: number;
  is_active?: number;
}

export function useQueryBranchList(
  params?: MortgageListParams,
  options?: SWROptions<Types.Branch.BranchList, MortgageListParams>
) {
  const res = useQuerySWR(endpoints.branch.list, params, options);
  return res;
}

export interface BranchLogParams {
  branch_id: string;
  type?: string | null;
  page?: string | null;
  limit?: string | null;
}

export function useQueryBranchLog(
  params?: BranchLogParams,
  options?: SWROptions<Types.Branch.BranchLogList, BranchLogParams>
) {
  const res = useQuerySWR(endpoints.branch.log, params, options);
  return res;
}

export interface BranchDetailParams {
  with_credit?: number;
  with_created_by?: number;
}

export function useQueryBranchDetail(
  id?: string,
  params?: BranchDetailParams,
  options?: SWROptions<Types.Branch.BranchItem, BranchDetailParams>
) {
  const res = useQuerySWR(endpoints.branch.root(id), params, options);
  return res;
}

export interface BranchCreateParams {
  name: string | null;
  address: string | null;
  lat?: string | null;
  lang?: string | null;
}

export function useCreateBranch(
  params?: BranchCreateParams,
  options?: SWROptions<Types.Branch.BranchItem, BranchCreateParams>
) {
  const res = useMutateSWR(endpoints.branch.create, params, options);
  return res;
}

export interface BranchUpdateParams {
  name: string | null;
  address: string | null;
  lat?: string | null;
  lang?: string | null;
}

export function useUpdateBranch(
  id?: string,
  params?: BranchUpdateParams,
  options?: SWROptions<Types.User.UserInfo, BranchUpdateParams>
) {
  const res = useMutateSWR(endpoints.branch.update(id), params, { ...options, method: 'PUT' });
  return res;
}
