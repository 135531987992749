import { Button } from '@mui/material';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import UserCrudForm from '../user-crud-form';
import Container from '@mui/material/Container';
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import PermissionGuard from 'src/components/permission/role-guard';
import { useAuthContext } from 'src/auth/hooks';

export const POST_SORT_OPTIONS = [
  { value: 'ASC', label: 'ascending' },
  { value: 'DESC', label: 'descending' },
];

export interface SearchOptions {
  title: string;
  value: string;
}
export interface SearchOptionsProps {
  limit: string;
  page: string;
  sort: string;
}

const defaultFilters: SearchOptionsProps = {
  limit: '30',
  page: '1',
  sort: 'DESC',
};

// ----------------------------------------------------------------------

export default function () {
  const { t } = useLocales();
  const { role } = useAuthContext();
  const settings = useSettingsContext();

  return (
    <PermissionGuard hasContent permission={'create_staff'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'md'}>
        <CustomBreadcrumbs
          heading={t(
            role === 'super_admin'
              ? t('create_admin')
              : role === 'admin'
              ? t('create_manager')
              : t('create_staff')
          )}
          links={[{}]}
          action={
            <Button component={RouterLink} href={paths.dashboard.user.root} variant="contained">
              {t('staff_lists')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 2 },
          }}
        />

        <UserCrudForm />
      </Container>
    </PermissionGuard>
  );
}
