// ----------------------------------------------------------------------
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  CardContent,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import { useLocales } from 'src/locales';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBoolean } from 'src/hooks/use-boolean';
import { useMutateDeposit } from 'src/api/operation';
import { yupResolver } from '@hookform/resolvers/yup';
import PermissionGuard from 'src/components/permission/role-guard';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useChangePassword } from 'src/api/user';

interface ClearableAutocompleteRef {
  clear: () => void;
}

export interface DepositProps {
  open: boolean;
  userId: number | null;
  onClose: VoidFunction;
}

export default function ({ open, onClose, userId, ...other }: DepositProps) {
  const { t } = useLocales();
  const password = useBoolean();
  const autoCompleteRef = useRef<ClearableAutocompleteRef>();

  const { isLoading: isLoadingUserTransfer, mutate: onChangePassword } = useChangePassword(
    undefined,
    {
      onSuccess: ({ message }) => {
        reset();
        onClose();
        enqueueSnackbar(message);
      },
      onError: (er) => {
        onClose();
        enqueueSnackbar(er.message, { variant: 'error' });
      },
    }
  );

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(6, 'Password must be at least 6 characters')
      .test(
        'no-match',
        'New password must be different than old password',
        (value, { parent }) => value !== parent.oldPassword
      ),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = handleSubmit(async (_params) => {
    const variable = {
      old_password: _params?.oldPassword,
      new_password: _params?.newPassword,
    };
    await onChangePassword(variable);
  });

  useEffect(() => {
    reset();
  }, [onClose]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <PermissionGuard hasContent sx={{ py: 10 }}>
        <DialogTitle sx={{ pb: 2 }}>{t('change_password')}</DialogTitle>
        <DialogContent sx={{ typography: 'body2', padding: 0 }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid gridColumn={12}>
              <CardContent>
                <Box
                  rowGap={2}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                  }}
                >
                  <RHFTextField
                    name="oldPassword"
                    type={password.value ? 'text' : 'password'}
                    label={t('old_password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RHFTextField
                    name="newPassword"
                    label={t('new_password')}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <Stack component="span" direction="row" alignItems="center">
                        <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Password must
                        be minimum 6+
                      </Stack>
                    }
                  />

                  <RHFTextField
                    name="confirmNewPassword"
                    type={password.value ? 'text' : 'password'}
                    label={t('confirm_new_password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </CardContent>
            </Grid>
            <DialogActions>
              <Button variant="outlined" color="inherit" onClick={onClose}>
                Cancel
              </Button>
              {/* {action} */}
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting || isLoadingUserTransfer}
              >
                {t('submit')}
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </DialogContent>
      </PermissionGuard>
    </Dialog>
  );
}
