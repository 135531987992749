// ----------------------------------------------------------------------
import {
  Card,
  Grid,
  Dialog,
  Button,
  IconButton,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { useLocales } from 'src/locales';
import endpoints from 'src/api/endpoints';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { Upload } from 'src/components/upload';
import { useBoolean } from 'src/hooks/use-boolean';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatUrl } from 'src/utils/format-img-url';
import axiosInstance from 'src/utils/axios-instanct';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutataWithdraw } from 'src/api/operation';
import { useQueryString } from 'src/utils/use-query-string';
import PermissionGuard from 'src/components/permission/role-guard';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface ClearableAutocompleteRef {
  clear: () => void;
}

export interface DepositProps {
  open: boolean;
  title: React.ReactNode;
  withdrawId: number | null;
  withdrawType: string | null;
  onClose: VoidFunction;
  onSuccess: () => void;
}

export default function ({
  title,
  open,
  onClose,
  onSuccess,
  withdrawId,
  withdrawType,
  ...other
}: DepositProps) {
  const { t } = useLocales();
  const password = useBoolean();
  const { getQueryString } = useQueryString();
  const getUserId: string = getQueryString('user', '');
  const getbranchId: string = getQueryString('branch', '');
  const autoCompleteRef = useRef<ClearableAutocompleteRef>();
  const [isLoading, setIsLoading] = useState(false);
  const domain = process.env?.REACT_APP_HOST_API;
  const token = document.cookie.split('accessToken=')[1].split('; XSRF-')[0];
  const [file, setFile] = useState<File | string | null>(null);

  const NewUserSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    receipt_url: Yup.string(),
    password: Yup.string().required('Password is required'),
  });

  const { isLoading: isLoadingUserTransfer, mutate: onDeposit } = useMutataWithdraw(undefined, {
    onSuccess: ({ message }) => {
      reset();
      onSuccess();
      handleClearAutoComplete();
      enqueueSnackbar(message);
    },
    onError: (er) => {
      onSuccess();
      enqueueSnackbar(er.message, { variant: 'error' });
    },
  });

  const defaultValues = useMemo(
    () => ({
      amount: '',
      user_id: getUserId || '',
      branch_id: getbranchId || '',
      withdraw_type: 'user',
      receipt_url: '',
      password: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const handleClearAutoComplete = () => {
    if (autoCompleteRef?.current?.clear()) {
      autoCompleteRef?.current.clear();
    }
  };

  const onSubmit = handleSubmit(async (_params) => {
    const variable = {
      to_user_id: withdrawType === 'user' ? withdrawId : null,
      branch_id: withdrawType === 'branch' ? withdrawId : null,
      amount: Number(_params?.amount),
      receipt_url: _params?.receipt_url,
      password: _params?.password,
    };
    await onDeposit(variable);
  });

  const onUploadCustomerImage = async () => {
    const formData = new FormData();
    formData.append('image', file as any);
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${domain}${endpoints.customer.upload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setValue('receipt_url', response?.data?.data?.image_url || '');
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDropSingleFile = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
    },
    [setValue]
  );
  const imageUrl = watch('receipt_url');

  useEffect(() => {
    if (file) {
      onUploadCustomerImage();
    }
  }, [file]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <PermissionGuard hasContent permission={'withdraw_staff_operation'} sx={{ py: 10 }}>
        <DialogTitle sx={{ pb: 2 }}>
          {withdrawType === 'user'
            ? t('withdraw_from_user_id') + ' ' + withdrawId
            : t('withdraw_from_branch_id') + ' ' + withdrawId}
        </DialogTitle>
        <DialogContent sx={{ typography: 'body2', padding: 0 }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid gridColumn={12}>
              <CardContent>
                <Box
                  rowGap={2}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                  }}
                >
                  <RHFTextField
                    type="number"
                    size="medium"
                    name="amount"
                    label={t(`${'amount'}`)}
                  />
                  <RHFTextField
                    name="password"
                    label={t('password')}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Upload
                    thumbnail
                    loading={isLoading}
                    file={formatUrl(imageUrl || '')}
                    accept={{ 'image/*': [] }}
                    maxSize={3145728}
                    onDrop={handleDropSingleFile}
                    onDelete={() => {
                      setFile(null);
                      setValue('receipt_url', '');
                    }}
                  />
                </Box>
              </CardContent>
              <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                  Cancel
                </Button>

                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting || isLoadingUserTransfer}
                >
                  {t('submit')}
                </LoadingButton>
              </DialogActions>
            </Grid>
          </FormProvider>
        </DialogContent>
      </PermissionGuard>
    </Dialog>
  );
}
