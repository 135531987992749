import { useMemo } from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import UserCrudForm from '../user-crud-form';
import Container from '@mui/material/Container';
import { useQueryUserDetail } from 'src/api/user';
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import PermissionGuard from 'src/components/permission/role-guard';
import { LoadingScreen } from 'src/components/loading-screen';

export interface SearchOptions {
  title: string;
  value: string;
}
export interface SearchOptionsProps {
  limit: string;
  page: string;
  sort: string;
}

const defaultFilters: SearchOptionsProps = {
  limit: '30',
  page: '1',
  sort: 'DESC',
};

// ----------------------------------------------------------------------
interface Props {
  id?: string;
}
export default function ({ id }: Props) {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, refetch } = useQueryUserDetail(
    String(id),
    { with_branch: 1, with_credit: 1, with_created_by: 1 },
    {
      loadFirst: true,
      onSuccess: ({ data, message }) => {
        enqueueSnackbar(message);
      },
      onError: (er) => enqueueSnackbar(er?.message, { variant: 'error' }),
    }
  );
  const resultData = useMemo(() => {
    return data?.data;
  }, [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <PermissionGuard hasContent permission={'update_staff'} sx={{ py: 10 }}>
      <Container maxWidth={settings.themeStretch ? false : 'md'}>
        <CustomBreadcrumbs
          heading={t(id ? 'update_staff' : 'create_staff')}
          links={[{}]}
          action={
            <Button component={RouterLink} href={paths.dashboard.user.root} variant="contained">
              {t('staff_lists')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 2 },
          }}
        />

        <UserCrudForm currentUser={resultData} id={id} />
      </Container>
    </PermissionGuard>
  );
}
