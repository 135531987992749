import moment from 'moment';
import { m } from 'framer-motion';
import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import { _notifications } from 'src/_mock';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//

import { useLocales } from 'src/locales';
import {
  Box,
  Card,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Tooltip,
  useTheme,
} from '@mui/material';

import { useNotification } from 'src/contexts/notification/notification-provider';
import { useSettingsContext } from 'src/components/settings';
import AppWidget from 'src/sections/overview/app/app-widget';
import { LoadingScreen } from 'src/components/loading-screen';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import BadgeAnalytic from 'src/components/badge/badge-analytic';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'all',
    label: 'All',
    count: '',
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { t } = useLocales();
  const theme = useTheme();
  const router = useRouter();
  const settings = useSettingsContext();
  const { notificationList, refetchNotification, isloadingCount } = useNotification();

  const largePercent = (Number(notificationList?.larges) * 100) / Number(notificationList?.total);
  const smallPercent = (Number(notificationList?.smalls) * 100) / Number(notificationList?.total);
  const latePercent = (Number(notificationList?.lates) * 100) / Number(notificationList?.total);

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('notification')}
      </Typography>

      <Tooltip title={t('refresh')}>
        <IconButton onClick={() => refetchNotification()}>
          <Badge color="error" variant="dot" invisible={!settings.canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={'error'}
            >
              {notificationList?.total || 0}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderEmpty = (
    <ListItemButton
      disableRipple
      sx={{
        p: 1,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} py={5}>
        <Iconify icon={'ic:outline-notifications-off'} width={'40px'} />
        <Typography fontSize={'small'}>No notifactions lists</Typography>
      </Stack>
    </ListItemButton>
  );

  const renderList = (
    <Scrollbar>
      <Card>
        <Box p={2}>
          <Stack
            gap={2}
            direction="column"
            divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
            // sx={{ py: 2 }}
          >
            <Stack
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                drawer.onFalse();
                router?.push(paths.dashboard.report.repayment);
              }}
            >
              <BadgeAnalytic
                alignLeft={true}
                title={t(`badge.total_pawns`)}
                total={Number(parseFloat(notificationList?.total?.toString() || '0').toFixed(3))}
                percent={100}
                price={0}
                icon="solar:bill-list-bold-duotone"
                color={theme.palette.info.main}
              />
            </Stack>
            <Stack
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                drawer.onFalse();
                router?.push(paths.dashboard.report.repayment);
              }}
            >
              <BadgeAnalytic
                alignLeft={true}
                title={t(`badge.general_pawns`)}
                total={Number(parseFloat(notificationList?.larges?.toString() || '0').toFixed(3))}
                percent={Number(largePercent.toFixed(0))}
                price={0}
                icon="solar:file-check-bold-duotone"
                color={theme.palette.success.main}
              />
            </Stack>
            <Stack
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                drawer.onFalse();
                router?.push(paths.dashboard.report.repayment);
              }}
            >
              <BadgeAnalytic
                alignLeft={true}
                title={t(`badge.fast_pawns`)}
                total={Number(notificationList?.larges)}
                percent={Number(smallPercent.toFixed(0))}
                price={0}
                icon="solar:sort-by-time-bold-duotone"
                color={theme.palette.warning.main}
              />
            </Stack>
            <Stack
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                drawer.onFalse();
                router?.push(paths.dashboard.report.repayment);
              }}
            >
              <BadgeAnalytic
                alignLeft={true}
                title={t(`badge.late_pawns`)}
                total={Number(notificationList?.smalls)}
                percent={Number(latePercent.toFixed(0))}
                price={0}
                icon="solar:bell-bing-bold-duotone"
                color={theme.palette.error.main}
              />
            </Stack>
          </Stack>
        </Box>
      </Card>
    </Scrollbar>
  );

  // useEffect(() => {
  //   //refetch in 10 minute
  //   const timeRefetch = 600;
  //   const interval = setInterval(() => {
  //     refetchNotification();
  //   }, timeRefetch * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={notificationList?.total} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 280 },
        }}
      >
        {renderHead}
        <Divider />
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack> */}
        <Divider />
        {isloadingCount ? (
          <Box mt={1}>
            {new Array(5).fill(0).map((_, i) => {
              return (
                <Stack spacing={2} px={2} py={1} key={i}>
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={70}
                    sx={{ borderRadius: '10px' }}
                  />
                </Stack>
              );
            })}
          </Box>
        ) : (
          <>{notificationList && notificationList ? renderList : renderEmpty}</>
        )}
      </Drawer>
    </>
  );
}
