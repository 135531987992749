import { SWROptions, useMutateSWR, useQuerySWR } from 'src/utils/swr-instanct';
import endpoints from './endpoints';
import { Types } from 'src/generated/types';

export interface TransactionWithdrawParams {
  to_user_id: number | null;
  branch_id: number | null;
  amount: number | null;
  receipt_url?: string | null;
  password: string | null;
}

export function useMutataWithdraw(
  params?: TransactionWithdrawParams,
  options?: SWROptions<any, TransactionWithdrawParams>
) {
  const res = useMutateSWR(endpoints.operation.withdraw, params, options);
  return res;
}

export interface TransactionDepositParams {
  to_user_id: number | null;
  branch_id: number | null;
  amount: number | null;
  receipt_url?: string | null;
  password: string | null;
}
export function useMutateDeposit(
  params?: TransactionDepositParams,
  options?: SWROptions<any, TransactionDepositParams>
) {
  const res = useMutateSWR(endpoints.operation.deposit, params, options);
  return res;
}

export interface TransactionTopupParams {
  from_user_id: number | null;
  amount: number | null;
  receipt_url?: string | null;
  password: string | null;
}

export function useMutateTopup(
  params?: TransactionTopupParams,
  options?: SWROptions<any, TransactionTopupParams>
) {
  const res = useMutateSWR(endpoints.operation.topup, params, options);
  return res;
}

export interface TransactionTransferParams {
  to_user_id: number | null;
  amount: number | null;
  receipt_url?: string | null;
  password: string | null;
}

export function useMutateTransfer(
  params?: TransactionTransferParams,
  options?: SWROptions<{ data: Types.Report.Transfer }, TransactionTransferParams>
) {
  const res = useMutateSWR(endpoints.operation.transfer, params, options);
  return res;
}

export interface TransactionExpendParams {
  amount: number | null;
  description: string | null;
  receipt_url?: string | null;
  password: string | null;
}

export function useMutateExpend(
  params?: TransactionExpendParams,
  options?: SWROptions<Types.Report.UserReportItem, TransactionExpendParams>
) {
  const res = useMutateSWR(endpoints.operation.expend, params, options);
  return res;
}
