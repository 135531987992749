import { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// components
import { useLocales } from 'src/locales';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import BranchSelection from 'src/components/branch-selection';
import { SearchOptions, SearchOptionsProps } from '../../view/user-list-view';

// ----------------------------------------------------------------------

const ACTIVE_LIST = [
  { value: '1', label: 'active' },
  { value: '0', label: 'in_active' },
];

type Props = {
  isLoading?: boolean;
  filters: SearchOptionsProps;

  onHandleSearch: VoidFunction;
  onFilters: (name: string, value: string) => void;
  //
  searchOptions: SearchOptions[];
};

export default function UserTableToolbar({
  isLoading,
  filters,
  onFilters,
  onHandleSearch,
  searchOptions,
}: Props) {
  const { t } = useLocales();
  const { child } = useAuthContext();
  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event: SelectChangeEvent<string>) => {
      onFilters('role', event.target.value);
    },
    [onFilters]
  );

  const handleFilterSearch = useCallback(
    (event: SelectChangeEvent<string>) => {
      onFilters('searchOptions', event.target.value);
    },
    [onFilters]
  );

  const ROLE_LIST: SearchOptions[] =
    child?.length > 0 ? child?.map((x) => ({ value: x || '', title: x || '' })) : [];

  return (
    <>
      <Stack
        spacing={1}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5 },
        }}
      >
        {ROLE_LIST?.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 180 },
            }}
          >
            <InputLabel>{t('role_type')}</InputLabel>
            <Select
              value={filters?.role ? t(`${filters?.role}`) : t('select_role')}
              onChange={handleFilterRole}
              input={<OutlinedInput label={t('role_type')} />}
              renderValue={(selected) => {
                return t(`${selected.toLowerCase()}`);
              }}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <MenuItem value={''} sx={{ justifyContent: 'space-between', bgcolor: '#f4f6f8' }}>
                {t(`display_all`)}
                <Iconify color={'red'} icon={'system-uicons:reset'} />
              </MenuItem>

              {ROLE_LIST?.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={type.value?.toLowerCase() === filters.role?.toLowerCase()}
                  />
                  {t(`role_types.${type?.title.toLowerCase()}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <BranchSelection
          clearValue
          width={140}
          value={filters.branch_id || ''}
          onChangeUser={(id) => onFilters('branch_id', id)}
        />
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 140 },
          }}
        >
          <InputLabel>{t('active_status')}</InputLabel>
          <Select
            value={filters.is_active ? filters.is_active : ''}
            onChange={(event) => {
              onFilters('is_active', event.target.value);
            }}
            input={<OutlinedInput label={t('active_status')} />}
            renderValue={(selected) =>
              t(`${ACTIVE_LIST?.find((x) => x?.value === selected)?.label}`)
            }
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            <MenuItem value={''}>
              <Checkbox disableRipple size="small" checked={!filters?.is_active} />
              {t(`display_all`)}
            </MenuItem>
            {ACTIVE_LIST.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters?.is_active?.includes(option.value)}
                />
                {t(`${option.label}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 160 },
          }}
        >
          <InputLabel>{t('search_options')}</InputLabel>

          <Select
            value={filters?.searchOptions}
            onChange={handleFilterSearch}
            input={<OutlinedInput label={t('search_options')} />}
            renderValue={(selected) =>
              t(`${searchOptions?.find((x) => x?.value === selected)?.title}`)
            }
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {searchOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters?.searchOptions?.includes(option.value)}
                />
                {t(`${option.title}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            sx={{ minWidth: 160 }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                return onHandleSearch();
              }
            }}
            placeholder={t(`search`)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                  {filters?.name && filters?.name?.length > 0 && (
                    <IconButton onClick={() => onFilters('name', '')} sx={{ p: 0 }}>
                      <Iconify icon={'iconoir:delete-circle'} sx={{ color: 'red' }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          minWidth={{ md: '120px', sm: 1 }}
        >
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => onHandleSearch()}
            loading={isLoading}
          >
            {t('search')}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
