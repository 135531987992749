import endpoints from './endpoints';
import { Types } from 'src/generated/types';
import { SWROptions, useMutateSWR, useQuerySWR } from 'src/utils/swr-instanct';

export interface UserListParams {
  sort?: null | string;
  page?: null | string;
  limit?: null | string;
  search_name?: string | null;
  search_username?: string | null;
  branch_id?: string | null;
  with_customer?: number;
  with_created_by?: number;
  is_search_parent?: number;
  with_branch?: number;
  with_credit?: number;
}

export function useQueryUserList(
  params?: UserListParams,
  options?: SWROptions<Types.User.UserList, UserListParams>
) {
  const res = useQuerySWR(endpoints.user.list, params, options);
  return res;
}

export interface UserDetailParams {
  with_branch?: number;
  with_credit?: number;
  with_created_by?: number;
}

export function useQueryUserDetail(
  id?: string,
  params?: UserDetailParams,
  options?: SWROptions<Types.User.UserInfo, UserDetailParams>
) {
  const res = useQuerySWR(endpoints.user.root(id), params, options);
  return res;
}

export interface UserCreateParams {
  name: string | null;
  username: string | null;
  contact: string | null;
  branch_id: number | null;
  password: string | null;
}

export function useCreateUser(
  params?: UserCreateParams,
  options?: SWROptions<Types.User.UserInfo, UserCreateParams>
) {
  const res = useMutateSWR(endpoints.user.create, params, options);
  return res;
}

export interface UserUpdateParams {
  name: string | null;
  username?: string | null;
  contact: string | null;
  branch_id: number | null;
  password: string | null;
  with_credit?: number;
}

export function useUpdateUser(
  id?: string,
  params?: UserUpdateParams,
  options?: SWROptions<Types.User.UserInfo, UserUpdateParams>
) {
  const res = useMutateSWR(endpoints.user.update(id), params, { ...options, method: 'PUT' });
  return res;
}

export interface UserChangePasswordParams {
  old_password: string;
  new_password: string;
}

export function useChangePassword(
  params?: UserChangePasswordParams,
  options?: SWROptions<any, UserChangePasswordParams>
) {
  const res = useMutateSWR(endpoints.auth.password, params, options);
  return res;
}
