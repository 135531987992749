export function formatUrl(url: string | null) {
  const domain = process.env.REACT_APP_HOST_API ?? '';
  const length = domain?.length - 1;
  if (url && domain) {
    return `${domain[length] == '/' ? domain.substring(0, length) : domain}${url}`;
  }
  return '';
}

export function khCurrency(currency: any) {
  if (!!currency) {
    return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return currency;
}
