// @mui
import { Theme, SxProps } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
//
import EmptyContent from '../empty-content';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  notFound: boolean;
  colspan?: number;
  sx?: SxProps<Theme>;
};

export default function TableNoData({ notFound, colspan, sx }: Props) {
  const { t } = useLocales();
  return (
    <TableRow>
      {notFound ? (
        <TableCell colSpan={colspan || 12}>
          <EmptyContent
            filled
            title={t('no_data')}
            sx={{
              py: 10,
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={colspan || 12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
