import * as Yup from 'yup';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Label from 'src/components/label';
import { useLocales } from 'src/locales';
import { _phoneNumbers } from 'src/_mock';
import { useForm } from 'react-hook-form';

import { Types } from 'src/generated/types';
import Iconify from 'src/components/iconify';
import Grid from '@mui/material/Unstable_Grid2';
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'src/components/snackbar';

import { useCreateUser, useUpdateUser } from 'src/api/user';
import BranchSelection from 'src/components/branch-selection';
import FormProvider, { RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import {
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------

const LETTERS_OPTIONS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const NUMBER_OPTIONS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export type CrudCustomer = {
  name?: string | null;
  address?: string | null;
  contact?: string | null;
  id_card_url?: string | null;
  id_card_number?: string | null;
};

type Props = {
  id?: string;
  refetch?: () => void;
  currentUser?: Types.User.UserInfo | null;
};

export default function UserCrudForm({ id, refetch, currentUser }: Props) {
  const { t } = useLocales();
  const password = useBoolean();
  const { role, user, hasPermission } = useAuthContext();
  const isAdmin = role === 'admin';
  const isManager = role === 'manager';
  const isSuperAdmin = role === 'super_admin';
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isUpdateUser, mutate: onUpdateUser } = useUpdateUser(String(id), undefined, {
    onSuccess: (r) => {
      refetch;
      enqueueSnackbar(r?.message);
    },
    onError: (er) => {
      enqueueSnackbar(er?.message || '', { variant: 'error' });
    },
  });

  const { isLoading: isCreatingUser, mutate: onCreateUser } = useCreateUser(undefined, {
    onSuccess: (r) => {
      reset();
      enqueueSnackbar(r?.message, { variant: 'success' });
    },
    onError: (er) => {
      enqueueSnackbar(er?.message || '', { variant: 'error' });
    },
  });

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    username: Yup.string().required('Username is required'),
    username_a: Yup.string().required('Username is required'),
    username_b: Yup.string().required('Username is required'),
    username_c: Yup.string().required('Username is required'),
    contact: Yup.string().required('Contact is required').min(9).max(11),
    branch_id: Yup.string().when({
      is: () => hasPermission('list_branch'),
      then: () => Yup.string().required('Branch is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: id
      ? Yup.string().nullable()
      : Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .nullable(),
    is_active: Yup.boolean().default(true),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      username:
        currentUser?.username ||
        (!isSuperAdmin && (user?.username as string).length > 2
          ? 'AA'
          : isSuperAdmin
          ? ''
          : (user?.username as string)),
      username_a: 'A',
      username_b: 'A',
      username_c: 'A',
      contact: currentUser?.contact || '',
      branch_id: currentUser?.branch?.id?.toString() || '',
      password: '',
      confirmPassword: '',
      is_active: true,
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (_param) => {
    if (id) {
      const findUpdateField = {
        name: currentUser?.name === _param?.name ? null : _param?.name,
        username: currentUser?.username === _param?.username ? null : _param?.username,
        contact: currentUser?.contact === _param?.contact ? null : _param?.contact,
        branch_id: currentUser?.branch?.id === _param?.branch_id ? null : Number(_param?.branch_id),
        password: _param?.password,
        is_active: _param?.is_active === true ? 1 : 0,
      };
      onUpdateUser({ ...findUpdateField });
    } else {
      const variable = {
        name: _param?.name || '',
        username: isAdmin
          ? _param?.username_a + _param?.username_b
          : isManager
          ? _param.username + _param?.username_a + _param?.username_b + _param?.username_c
          : _param.username,
        contact: _param?.contact || '',
        branch_id: Number(_param?.branch_id) || null,
        password: _param?.password || '',
        is_active: _param?.is_active,
      };
      onCreateUser({ ...variable });
    }
  });

  const onGetUserId = (id: string) => {
    setValue('branch_id', id);
  };

  const SELECT_OPTIONS = useMemo(() => LETTERS_OPTIONS, [role]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card>
            <CardHeader
              title=""
              action={
                <Label mr={'10px'} variant="soft" color={watch('is_active') ? 'success' : 'error'}>
                  {`${watch('is_active') ? t('active') : t('in_active')}`}
                </Label>
              }
            />
            <CardContent>
              <Box
                mb={3}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: `repeat(${id || role !== 'manger' ? '1' : '2'}, 1fr)`,
                }}
              >
                <Box display="flex" flexDirection="row" gap={2}>
                  {(isManager || isSuperAdmin || id) && (
                    <RHFTextField
                      fullWidth
                      name="username"
                      label={t('username')}
                      onChange={(e) => setValue('username', e.target.value.trim())}
                      InputProps={{
                        readOnly: isSuperAdmin ? false : true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="mdi:user-lock" width={24} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}

                  {id || isSuperAdmin ? (
                    ''
                  ) : (
                    <>
                      <RHFSelect
                        name="username_a"
                        label=""
                        InputProps={{
                          readOnly: id ? true : false,
                        }}
                      >
                        {SELECT_OPTIONS.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                      <RHFSelect
                        name="username_b"
                        label=""
                        InputProps={{
                          readOnly: id ? true : false,
                        }}
                      >
                        {SELECT_OPTIONS.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </RHFSelect>

                      {isManager && (
                        <RHFSelect
                          name="username_c"
                          label=""
                          InputProps={{
                            readOnly: id ? true : false,
                          }}
                        >
                          {SELECT_OPTIONS.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      )}
                    </>
                  )}
                </Box>
                {/* <RHFTextField
                  fullWidth
                  name="username"
                  label={t('username')}
                  onChange={(e) => setValue('username', e.target.value.trim())}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="mdi:user-lock" width={24} />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <BranchSelection
                  requiredFieldMsg={errors?.branch_id?.message?.toString()}
                  value={watch('branch_id')?.toString() || ''}
                  onChangeUser={onGetUserId}
                />
              </Box>
              <Box
                mb={3}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: `repeat(${id ? '1' : '2'}, 1fr)`,
                }}
              >
                <RHFTextField
                  name="name"
                  label={t('name')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="solar:user-rounded-bold" width={24} />
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  type="tel"
                  name="contact"
                  label={t('phone_number')}
                  onChange={(e) => setValue('contact', e.target.value.trim())}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="solar:phone-bold" width={24} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                mb={3}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: `repeat(${id ? '1' : '2'}, 1fr)`,
                }}
              >
                <RHFTextField
                  name="password"
                  label="Password"
                  type={password.value ? 'text' : 'password'}
                  onChange={(e) => setValue('password', e.target.value.trim())}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify
                            icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {!id ? (
                  <RHFTextField
                    name="confirmPassword"
                    label="Confirm New Password"
                    type={password.value ? 'text' : 'password'}
                    onChange={(e) => setValue('confirmPassword', e.target.value.trim())}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
              </Box>
              {id && (
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <Stack sx={{ width: 150 }}>
                    <RHFSwitch
                      color="success"
                      name="is_active"
                      labelPlacement="start"
                      label={
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          {t('active_status')}
                        </Typography>
                      }
                      sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                    />
                  </Stack>
                </Box>
              )}

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting || isCreatingUser || isUpdateUser}
                >
                  {t(
                    `${
                      id
                        ? role === 'super_admin'
                          ? 'update_admin'
                          : role === 'admin'
                          ? 'update_manager'
                          : 'update_user'
                        : role === 'super_admin'
                        ? 'create_admin'
                        : role === 'admin'
                        ? 'create_manager'
                        : 'create_staff'
                    }`
                  )}
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
