import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
//CUSTOMER
const CustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/list'));
const CustomerDetailPage = lazy(() => import('src/pages/dashboard/loan-customer/detail'));
const CreateCustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/create'));
const EditCustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/edit'));

//MORTGAGE
const LargePawnPage = lazy(() => import('src/pages/dashboard/pawn/large-pawn-list'));
const SmallPawnPage = lazy(() => import('src/pages/dashboard/pawn/small-pawn-list'));
const EditMortgagePage = lazy(() => import('src/pages/dashboard/pawn/edit'));
const UpdateMortgagePage = lazy(() => import('src/pages/dashboard/pawn/detail'));
const MortgageCreatePage = lazy(() => import('src/pages/dashboard/pawn/create'));
const MortgageFastCreatePage = lazy(() => import('src/pages/dashboard/pawn/fast-create'));

//Report
const YourReportPage = lazy(() => import('src/pages/dashboard/report/your-report'));
const UserReportPage = lazy(() => import('src/pages/dashboard/report/user-report'));
const BranchReportPage = lazy(() => import('src/pages/dashboard/report/branch-report'));
const RepaymentRportPage = lazy(() => import('src/pages/dashboard/report/repayment-report'));

//USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const CreateUserPage = lazy(() => import('src/pages/dashboard/user/create'));
const UserDetailPage = lazy(() => import('src/pages/dashboard/user/detail'));
const UserUpdatePage = lazy(() => import('src/pages/dashboard/user/edit'));

//BRNACH
const BranchListPage = lazy(() => import('src/pages/dashboard/branch/list'));
const CreateBranchPage = lazy(() => import('src/pages/dashboard/branch/create'));
const BranchDetailPage = lazy(() => import('src/pages/dashboard/branch/detail'));
const BranchUpdatePage = lazy(() => import('src/pages/dashboard/branch/edit'));

//OPEREATION
const ExpendPage = lazy(() => import('src/pages/dashboard/operation/expend'));
const TransferPage = lazy(() => import('src/pages/dashboard/operation/transfer'));
const TopupPage = lazy(() => import('src/pages/dashboard/operation/topup'));
const DepositPage = lazy(() => import('src/pages/dashboard/operation/deposit'));
const WithdrawPage = lazy(() => import('src/pages/dashboard/operation/withdraw'));

//PERMISSION
const PermissionListPage = lazy(() => import('src/pages/dashboard/permission/list'));

//LOGS
const BranchLogPage = lazy(() => import('src/pages/dashboard/logs/branch'));
const UserLogPage = lazy(() => import('src/pages/dashboard/logs/user'));
const PawnLogPage = lazy(() => import('src/pages/dashboard/logs/pawn'));

//pawn type
const PawnsTypePage = lazy(() => import('src/pages/dashboard/collateral/list'));
const CreatePawnsTypePage = lazy(() => import('src/pages/dashboard/collateral/create'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },

      {
        path: 'report',
        children: [
          { element: <YourReportPage />, index: true },
          { path: 'yourReport', element: <YourReportPage /> },
          { path: 'user', element: <UserReportPage /> },
          { path: 'branch', element: <BranchReportPage /> },
          { path: 'repayment', element: <RepaymentRportPage /> },
        ],
      },
      {
        path: 'customer',
        children: [
          { element: <CustomerPage />, index: true },
          { path: 'create', element: <CreateCustomerPage /> },
          { path: 'edit/:id', element: <EditCustomerPage /> },
          { path: ':id', element: <CustomerDetailPage /> },
        ],
      },
      {
        path: 'pawn',
        children: [
          { element: <LargePawnPage />, index: true },
          { path: 'small', element: <SmallPawnPage /> },
          { path: 'create', element: <MortgageCreatePage /> },
          { path: 'fast/create', element: <MortgageFastCreatePage /> },
          { path: 'edit/:id', element: <EditMortgagePage /> },
          { path: 'detail/:id', element: <UpdateMortgagePage /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'create', element: <CreateUserPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'detail/:id', element: <UserDetailPage /> },
          { path: 'edit/:id', element: <UserUpdatePage /> },
          { path: 'permission', element: <PermissionListPage /> },
        ],
      },
      {
        path: 'branch',
        children: [
          { element: <BranchListPage />, index: true },
          { path: 'create', element: <CreateBranchPage /> },
          { path: 'list', element: <BranchListPage /> },
          { path: 'detail/:id', element: <BranchDetailPage /> },
          { path: 'edit/:id', element: <BranchUpdatePage /> },
        ],
      },
      {
        path: 'operation',
        children: [
          { element: <ExpendPage />, index: true },
          { path: 'expend', element: <ExpendPage /> },
          { path: 'transfer', element: <TransferPage /> },
          { path: 'topup', element: <TopupPage /> },
          { path: 'deposit', element: <DepositPage /> },
          { path: 'withdraw', element: <WithdrawPage /> },
        ],
      },
      {
        path: 'log',
        children: [
          { element: <BranchLogPage />, index: true },
          { path: 'branch/:id', element: <BranchLogPage /> },
          { path: 'user/:id', element: <UserLogPage /> },
          { path: 'pawn/:id', element: <PawnLogPage /> },
        ],
      },
      {
        path: 'collateral',
        children: [
          { element: <CreatePawnsTypePage />, index: true },
          { path: 'type', element: <CreatePawnsTypePage /> },
          { path: 'type/list', element: <PawnsTypePage /> },
        ],
      },
    ],
  },
];
