// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';
import axiosInstance from 'src/utils/axios-instanct';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export const setLocalCookie = (accessToken: string | null, expiresIn: number = 0) => {
  var expires = '';
  var date = new Date();
  date.setTime(date.getTime() + expiresIn ?? 0);
  expires = '; expires=' + date.toUTCString();
  document.cookie = `accessToken=${accessToken || ''}${expires};Path=/`;

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const resetOldToken = async () => {
  var reset: any = false;
  let cookieArray = document.cookie.split(';');
  for (var i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split('=');
    if (cookiePair[0].trim() == 'resettoken') {
      reset = decodeURIComponent(cookiePair[1]);
    }
  }
  if (!reset) {
    removeAllCookie();
    document.cookie = `resettoken=true; expires=1000000000; Path=/`;
  }
};

export const removeAllCookie = () => {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  delete axios.defaults.headers.common.Authorization;
  delete axiosInstance.defaults.headers.common.Authorization;
};
