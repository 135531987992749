import Link from '@mui/material/Link';
import { useLocales } from 'src/locales';
import Avatar from '@mui/material/Avatar';
import { Types } from 'src/generated/types';
import { useRouter } from 'src/routes/hook';
import Iconify from 'src/components/iconify';
import { Button, Stack } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchNotFound from 'src/components/search-not-found';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

// ----------------------------------------------------------------------
interface ClearableAutocompleteRef {
  clear: () => void;
}
interface Props {
  query: string;
  value?: string;
  disable?: boolean;
  placeholder?: string;
  requiredFieldMsg?: string | null;
  results: Types.User.UserInfo[] | [];
  onGetUserId: (id: string) => void;
  onSearch: (inputValue: string) => void;
  createCustomerLink?: string;
  loading?: boolean;
}

const PostSearch = forwardRef<unknown, Props>(
  (
    {
      disable,
      query,
      results,
      placeholder,
      onSearch,
      onGetUserId,
      requiredFieldMsg,
      createCustomerLink,
      loading,
    },
    ref
  ) => {
    const router = useRouter();
    const { t } = useLocales();
    const inputRef = useRef<HTMLInputElement>();
    const [value, setValue] = useState<Types.User.UserInfo | null>(null);

    const handleClear = () => {
      setValue(null);
    };

    const handleClick = (id: string) => {
      onGetUserId(id);
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        setValue(null);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }));
    return (
      <Stack position={'relative'}>
        <Autocomplete
          disabled={disable}
          value={value}
          loading={loading}
          autoHighlight
          popupIcon={null}
          options={results}
          sx={{ width: { xs: 1, sm: 1 } }}
          onChange={(event: HTMLInputElement | any, value) => {
            if (event?.key === 'Enter') {
              setValue(value);
              return handleClick(value?.id?.toString() || '');
            }
          }}
          onInputChange={(event, newValue) => onSearch(newValue)}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={
            <Stack flexDirection={'column'} gap={2}>
              <SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />
              <Button
                variant="contained"
                color="success"
                onClick={() => router.push(createCustomerLink || '')}
              >
                {t('create_new_user')}
              </Button>
            </Stack>
          }
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: {
                minWidth: 320,
              },
            },
            paper: {
              sx: {
                [` .${autocompleteClasses.option}`]: {
                  pl: 0.75,
                },
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              inputRef={inputRef}
              {...params}
              type="tel"
              error={requiredFieldMsg && requiredFieldMsg?.length > 0 ? true : false}
              placeholder={t(`${placeholder}`)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{ mr: -3 }} /> : null}
                    <div
                      onClick={() => {
                        handleClick('');
                        handleClear();
                      }}
                    >
                      {params.InputProps.endAdornment}
                    </div>
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, post, { inputValue }) => {
            const matches = match(post?.name || '', inputValue);
            const parts = parse(post?.name || '', matches);

            return (
              <li {...props} key={post.id}>
                <Avatar
                  key={post.id}
                  alt={post.name || ''}
                  src={''}
                  variant="rounded"
                  sx={{ width: 48, height: 48, flexShrink: 0, mr: 1.5, borderRadius: 1 }}
                />
                <Stack
                  width={'100%'}
                  onClick={() => {
                    setValue(post);
                    handleClick(post?.id?.toString() ?? '');
                  }}
                >
                  <Link key={inputValue} underline="none">
                    {parts.map((part, index) => (
                      <Typography
                        key={index}
                        component="span"
                        color={part.highlight ? 'primary' : 'textPrimary'}
                        sx={{
                          typography: 'body2',
                          fontWeight: part.highlight ? 'fontWeightSemiBold' : 'fontWeightMedium',
                        }}
                      >
                        {part.text}
                      </Typography>
                    ))}
                  </Link>
                  <Typography component="span" color={'textPrimary'}>
                    {post.contact}
                  </Typography>
                </Stack>
              </li>
            );
          }}
        />
        {requiredFieldMsg && (
          <Typography fontSize={'small'} pl={2} mt={0.5} color={'#FF5630'}>
            {requiredFieldMsg}
          </Typography>
        )}
      </Stack>
    );
  }
);

export default PostSearch;
